import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import NewsletterSection from '../components/NewsletterSection/NewsletterSection';
import ResourcesSection from '../components/ResourcesSection/ResourcesSection';
import SiteMeta from '../components/SiteMeta';

const Subscribe = ({ data }) => {
  const { contentfulStaticHomePage: {
    newsletterSection,
    resourcesSection,
    metaTitle,
    metaDescription
  } } = data;
  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <NewsletterSection {...newsletterSection} />
      <ResourcesSection {...resourcesSection} />
    </Layout>
  )
}

export default Subscribe

export const query = graphql`
  query {
    contentfulStaticHomePage {
      metaTitle
      metaDescription
      newsletterSection {
        ...NewsletterSection
      }
      resourcesSection {
        ...ResourcesSection
      }
    }
  }
      `;
