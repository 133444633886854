import React, { useState, useEffect, useRef } from 'react';
import Image from 'gatsby-image';
import RichText from '../RichText';
import { Form } from '../Form';
import { isMobile } from '../../utils';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { inViewTriggerThresholds } from '../../animationGlobals';

const NewsletterSection = ({
  mobileImage,
  desktopImage,
  title,
  bodyText,
  formTitle,
  form,
}) => {
  const [formStatus, setFormStatus] = useState({
    submitted: false,
    success: false,
  });

  const mobile = isMobile();
  const [useMobileLayout, setUseMobileLayout] = useState(false);

  useEffect(() => {
    if (useMobileLayout !== mobile) setUseMobileLayout(mobile);
  }, [mobile]);

  const successMessage = form.successTitle;
  const errorMessage = form.errorTitle;

  const sectionRef = useRef();
  const titleRef = useRef();
  const bodyRef = useRef();
  const formRef = useRef();
  const imageRef = useRef();

  useEffect(() => {
    // const sectionEl = sectionRef.current;
    // const titleEl = titleRef.current;
    // const bodyEl = bodyRef.current;
    // const formEl = formRef.current;
    // const imageEl = imageRef.current;

    // const toCommon = {
    //   duration: 0.5,
    //   ease: 'power4.out',
    // };

    // const delayCommon = `<${toCommon.duration * 0.25}`;

    // const FadeIn = [
    //   {
    //     opacity: 0,
    //   },
    //   {
    //     opacity: 1,
    //     duration: 1,
    //     ease: 'power2.out',
    //   },
    //   delayCommon,
    // ];

    // const InFromBottom = [
    //   {
    //     opacity: 0,
    //     y: '25%',
    //   },
    //   {
    //     opacity: 1,
    //     y: '0%',
    //     ...toCommon,
    //   },
    //   delayCommon,
    // ];

    // const animation = gsap
    //   .timeline({})
    //   .fromTo(imageEl, ...FadeIn)
    //   .fromTo(titleEl, ...InFromBottom)
    //   .fromTo(bodyEl, ...InFromBottom)
    //   .fromTo(formEl, ...InFromBottom);

    // const trigger = ScrollTrigger.create({
    //   trigger: sectionEl,
    //   ...inViewTriggerThresholds,
    //   animation: animation,
    // });

    // return () => {
    //   animation.progress(1);
    //   animation.kill();
    //   trigger.kill();
    // };
  }, []);

  return (
    <section ref={sectionRef} className="newsletter-section">
      <div className="newsletter-section__inner">
        <div ref={imageRef} className="newsletter-section__image">
          <Image
            imgStyle={{ objectFit: 'cover' }}
            fluid={useMobileLayout ? mobileImage.fluid : desktopImage.fluid}
            alt={mobileImage.description}
          />
        </div>
        <div className="newsletter-section__content">
          <h2 ref={titleRef} className="newsletter-section__title">
            {title}
          </h2>
          <div ref={bodyRef} className="newsletter-section__body">
            <RichText source={bodyText.json} />
          </div>
          {formStatus.submitted ? (
            <p className="newsletter-section__form-title">
              {formStatus.success ? successMessage : errorMessage}
            </p>
          ) : (
            <div ref={formRef} className="newsletter-section__form-container">
              <div className="newsletter-section__form-title">{formTitle}</div>
              <div className="newsletter-section__form">
                <Form
                  {...form}
                  onSubmitSuccess={() =>
                    setFormStatus({ submitted: true, success: true })
                  }
                  onSubmitFail={() =>
                    setFormStatus({ submitted: true, success: false })
                  }
                  onSubmitError={() =>
                    setFormStatus({ submitted: true, success: false })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default NewsletterSection;
